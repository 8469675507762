
import BudouX from '../components/BudouX.vue'
import { defineComponent } from 'vue'

export default defineComponent({
	components: {
		BudouX,
	},
	props: ['reviews', 'showHospitalName'],
})
