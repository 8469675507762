
import { ref, defineComponent } from 'vue'
import { loadDefaultJapaneseParser } from 'budoux'

export default defineComponent({
	data() {
		function escapeHtml(str: string) {
			str = str.replace(/&/g, '&amp;')
			str = str.replace(/>/g, '&gt;')
			str = str.replace(/</g, '&lt;')
			str = str.replace(/"/g, '&quot;')
			str = str.replace(/'/g, '&#x27;')
			str = str.replace(/`/g, '&#x60;')
			str = str.replace(/\n/g, '<br />')
			return str
		}
		const parser = loadDefaultJapaneseParser()
		const slots = this.$slots
		const defSlot = slots.default
		if (!defSlot) return { data: [] }
		const slot = defSlot()[0].children?.toString()
		if (!slot) return { data: [] }
		const sanitized = escapeHtml(slot)
		const arr = parser.parse(sanitized)
		return {
			data: arr,
		}
	},
})
